/*@import '~antd/dist/reset.css';*/

html, body {
  height: 100%;
}

.app-container {
  width: 100%;
  position: relative;
}

.app-content {
  padding: 24px;
  padding-bottom: 2.5rem;
  min-height: 78vh;
}

.app-footer {
  background-color: #f2f2f2;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-link {
  color: #1890ff;
  cursor: pointer;
}

.bigger-image {
  width: 150px;
  margin: .5rem;
}

.logo {
  width: 60px;
}

.header {
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin-bottom: 50px;
  background-color: #f2f2f2;
}

.header-logo {
  width: 160px;
  padding: 10px;
}

.content {
  background-color: #fff; 
  padding-bottom: 150px; 
  padding: 5%;
}


.data-select-modal-icon {
  font-size: 1.4rem;
  margin: 10px;
  float: right;
}

.translation-table-true-haplotype {
  background-color: yellow;
}

.translation-table-your-data, .translation-table-your-data:hover td {
  background-color: #f0f8ff !important;
}

.header-menu {
  margin-right: 1rem;
  background: transparent;
  font-size: 1rem;
  border: none;
  position: relative;
}

.inst-logo {
  float: right;
}

.field-size-lg {
  height: 31px;
  padding: 0 11px;
  margin-right: 2px;
}

.report {
  border: 1px solid lightgray;
  padding: 10px;
  margin-top: 1.2rem;
}

.centered-text {
  text-align: center;
}